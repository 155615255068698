<template>
	<b-card
		:header="this.$i18n.t('products.products')"
		header-bg-variant="primary"
		header-text-variant="white"
	>
		<ProductPopupVue :data="modalProduct" />
		<ModalProductMobileView :product="mobileViewProduct" />
		<div class="filters">
			<b-form-group
				:label="this.$i18n.t('global.search')"
				label-for="search"
				class="form-group"
			>
				<b-form-input
					id="search"
					v-model="searchQuery"
					:placeholder="this.$i18n.t('global.search-term')"
					autofocus
				/>
			</b-form-group>
		</div>
		<b-table
			ref="refProductsListTable"
			bordered
			hover
			responsive
			class="shadow-sm rounded products-table"
			thead-tr-class="order-main-thead"
			:items="productsList"
			:fields="fields"
			primary-key="id"
			show-empty
			empty-text="No matching records found"
			small
			@row-clicked="onRowClicked"
		>
			<template #cell(actions)="data">
				<div class="d-flex">
					<b-button
						variant="flat-primary"
						class="btn-icon"
						@click="editProductButton(data.item)"
					>
						<feather-icon size="16" icon="EditIcon" />
					</b-button>
					<b-button
						variant="flat-primary"
						class="btn-icon"
						@click="dublicateProduct(data.item.id)"
					>
						<feather-icon size="16" icon="CopyIcon" />
					</b-button>
					<b-button
						class="btn-icon"
						variant="flat-danger"
						@click="removeProductButton(data.item)"
					>
						<feather-icon size="16" icon="TrashIcon" />
					</b-button>
					<b-button
							class="btn-icon"
							variant="flat-success"
							@click="getMobileView(data.item)"
					>
						<feather-icon size="16" icon="EyeIcon" />
					</b-button>
				</div>
			</template>
		</b-table>
		<Pagination
			:fetch-data="getProducts"
			fetch-type="products"
			:total-rows="totalRows"
			:take.sync="take"
		/>
	</b-card>
</template>

<script>
import { BTable, BCard, BFormInput, BFormGroup, BButton } from 'bootstrap-vue';

import { mapActions, mapState } from 'vuex';
import ModalProductMobileView from "@/views/products/components/ModalProductMobileView.vue";
import ProductPopupVue from './ProductPopup.vue';
import Pagination from '../components/Pagination/Pagination.vue';

export default {
	components: {
		ModalProductMobileView,
		BTable,
		BCard,
		BButton,
		BFormInput,
		BFormGroup,
		ProductPopupVue,
		Pagination,
	},
	data() {
		return {
			productsList: [],
			mobileViewProduct: null,
			searchQuery: '',
			modalProduct: null,
			take: 20,
			skip: 0,
			totalRows: 0,
			fields: [
				{
					label: this.$i18n.t('products.actions'),
					key: 'actions',
				},
				{
					label: this.$i18n.t('products.name'),
					key: 'nameGeo',
					formatter: (value, key, item) => `${item.nameGeo} / ${item.nameEng}`,
					tdClass: 'nameColumn',
				},
				{
					key: 'price',
					label: this.$i18n.t('products.price'),
					sortable: true,
					formatter: (value, key, item) => `${item.price.toFixed(2)} ₾`,
				},
				{
					key: 'count',
					label: this.$i18n.t('products.count'),
				},
				{
					key: 'percentCommissionValue',
					label: this.$i18n.t('products.comission'),
				},
				{
					label: this.$i18n.t('products.created_at'),
					key: 'createdAt',
					sortable: false,
				},
				{
					label: this.$i18n.t('products.category'),
					key: 'productCategory',
					formatter: (value) =>
						`${value.productCategoryNameGeo} / ${value.productCategoryNameEng}`,
					sortable: false,
				},
				{
					label: this.$i18n.t('products.sub-category'),
					key: 'productSubCategory',
					formatter: (value) =>
						`${value.productSubCategoryNameGeo} / ${value.productSubCategoryNameEng}`,
					sortable: false,
				},
				{
					label: this.$i18n.t('products.generic-category'),
					key: 'productGenericCategory',
					formatter: (value) =>
						`${value.productGenericCategoryNameGeo} / ${value.productGenericCategoryNameEng}`,
					sortable: false,
				},
				{
					label: this.$i18n.t('products.sku'),
					key: 'upcOrSku',
				},
			],
		};
	},

	computed: {
		...mapState('products', ['products']),
	},
	watch: {
		searchQuery(newVal) {
			this.getProducts({
				skip: 0,
				take: this.take,
				searchKey: newVal,
				productType: 'products',
			});
		},
		products(newVal) {
			this.productsList = newVal.products;
			this.totalRows = newVal.count;
		},
	},
	created() {
		this.getProducts({
			skip: 0,
			take: this.take,
			searchKey: '',
			productType: 'products',
		});
	},
	methods: {
		...mapActions('products', ['getProducts']),
		dublicateProduct(id) {
			this.$router.push(`/copy-product/${id}/approved`);
		},
		editProductButton(prod) {
			this.$router.push(`/edit-product/${prod.id}/approved`);
		},
		removeProductButton(prod) {
			console.log(prod);
		},
		onRowClicked(item) {
			console.log('popup item', item);
			this.modalProduct = item;
			this.$bvModal.show('product-details-modal');
		},
		getMobileView(prod) {
			console.log('prod mobile view', prod)
			this.mobileViewProduct = prod;
			this.$bvModal.show('product-mobile-modal');
		},
	},
};
</script>

<style>
.filters .form-group {
	max-width: 300px;
}
</style>
