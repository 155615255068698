<!-- eslint-disable vue/html-self-closing -->
<template>
	<b-modal
			id="product-mobile-modal"
			ref="product-mobile-modal"
			ok-only
	>
		<div v-if="product" class="mobile-container gray-text">
			<div class="mobile-top">
				<svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M9.5 2L1.5 10L9.5 18" stroke="#F5F5F7" stroke-width="3" stroke-linecap="round"
								stroke-linejoin="round"
					/>
				</svg>
				<p>
					საჩუქრის დეტალები
				</p>
				<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect opacity="0.4" width="40" height="40" rx="20" fill="url(#paint0_linear_2982_5346)"/>
					<rect width="2.5796" height="16.061" rx="1.2898"
								transform="matrix(0.71526 0.698858 -0.71526 0.698858 24.8213 13.6396)" fill="white"
					/>
					<rect width="2.5796" height="16.061" rx="1.2898"
								transform="matrix(0.71526 -0.698858 0.71526 0.698858 13.333 15.1362)" fill="white"
					/>
					<defs>
						<linearGradient id="paint0_linear_2982_5346" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
							<stop stop-color="white"/>
							<stop offset="1" stop-color="white"/>
						</linearGradient>
					</defs>
				</svg>
			</div>
			<div class="mobile-image-container">
				<img :src="mainImage"/>
				<div class="slider-svg">
					<svg width="55" height="7" viewBox="0 0 55 7" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="39.5" cy="3.5" r="3.5" fill="#B8B7BD"/>
						<circle cx="15.5" cy="3.5" r="3.5" fill="#B8B7BD"/>
						<circle cx="3.5" cy="3.5" r="3.5" fill="#B8B7BD"/>
						<circle cx="51.5" cy="3.5" r="3.5" fill="#F5F5F7"/>
						<circle cx="27.5" cy="3.5" r="3.5" fill="#B8B7BD"/>
					</svg>
				</div>
			</div>
			<div class="mobile-title d-flex justify-content-between align-items-center">
				<div>
					<p class="product-title mb-05">{{ product.nameGeo }}</p>
					<div class="d-flex align-items-center">
						<p class="brand-title mb-0 mr-05">თაიმი</p>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect width="24" height="24" rx="12" fill="#C3DAFD" fill-opacity="0.4"/>
							<path d="M10 7L15 12L10 17" stroke="#4785E3" stroke-width="3" stroke-linecap="round"
										stroke-linejoin="round"
							/>
						</svg>
					</div>
				</div>
				<div>
					<svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect opacity="0.9" y="0.5" width="42" height="42" rx="21" fill="#F5F5F7"/>
						<path
								d="M25.4954 12.5C25.9901 12.5001 26.4771 12.6226 26.9129 12.8565C27.3487 13.0905 27.7199 13.4287 27.9933 13.8409C28.2667 14.2531 28.4339 14.7266 28.4799 15.2191C28.5259 15.7116 28.4493 16.2079 28.257 16.6636C28.0647 17.1194 27.7626 17.5204 27.3776 17.8311C26.9927 18.1417 26.5369 18.3523 26.0508 18.444C25.5647 18.5357 25.0635 18.5058 24.5918 18.3567C24.1201 18.2077 23.6926 17.9443 23.3475 17.59L17.8905 20.71C18.031 21.2263 18.031 21.7707 17.8905 22.287L23.3484 25.406C23.8578 24.8837 24.5402 24.5651 25.2676 24.5098C25.9951 24.4546 26.7177 24.6665 27.3001 25.1058C27.8826 25.5452 28.2848 26.1818 28.4315 26.8964C28.5782 27.6111 28.4592 28.3547 28.097 28.9879C27.7347 29.6212 27.1539 30.1006 26.4635 30.3364C25.7731 30.5721 25.0205 30.5481 24.3465 30.2687C23.6726 29.9893 23.1237 29.4737 22.8026 28.8186C22.4816 28.1635 22.4104 27.4138 22.6024 26.71L17.1454 23.59C16.7294 24.0171 16.1954 24.3105 15.6117 24.4325C15.028 24.5546 14.4212 24.4998 13.8689 24.2752C13.3165 24.0507 12.8436 23.6665 12.5107 23.1717C12.1778 22.677 12 22.0943 12 21.498C12 20.9017 12.1778 20.319 12.5107 19.8243C12.8436 19.3295 13.3165 18.9453 13.8689 18.7208C14.4212 18.4962 15.028 18.4414 15.6117 18.5635C16.1954 18.6855 16.7294 18.9789 17.1454 19.406L22.6024 16.286C22.4813 15.8413 22.4643 15.3746 22.5528 14.9222C22.6414 14.4698 22.833 14.044 23.1129 13.6777C23.3928 13.3115 23.7533 13.0147 24.1665 12.8104C24.5798 12.6062 25.0345 12.4999 25.4954 12.5Z"
								fill="#39373D"
						/>
					</svg>
				</div>
			</div>
			<div v-if="product.productConfigurations.length" class="mobile-configuration">
				<div class="single-configuration">
					<p class="mb-0">ფერი</p>
					<div class="d-flex">
						<div v-for="config in product.productConfigurations" :key="config.id" class="color-circle color rounded-circle mr-05" :style="`background: ${config.colorCode}`"></div>
					</div>
				</div>
				<div v-for="option in product.productConfigurations[0].options" :key="option.id" class="single-configuration">
					<p class="mb-0">ზომა</p>
					<div class="d-flex">
						<p class="mb-0 bold-text">{{option.sizeOptionGeo}}</p>
					</div>
				</div>
			</div>
			<div class="mobile-sale">
				<div class="sale-top">
					<p class="mb-0 text-white">აქციიის უგრძელესი დასახელება რომელიც შეიძლება გადაიჭიმოს არა ერთ არამედ ორ
						ხაზზე</p>
				</div>
				<div class="sale-bottom">
					<div class="sale-more-container">
						<div class="sale-more-btn">
							გაგრძელება
						</div>
					</div>
					<p>
						აქციიის უგრძელესი დასახელება რომელიც შეიძლება გადაიჭიმოს არა ერთ არამედ ორ
						ხაზზეაქციიის უგრძელესი დასახელება რომელიც შეიძლება გადაიჭიმოს არა ერთ არამედ ორ
						ხაზზე
					</p>
				</div>
			</div>
			<div class="mobile-description">
				<div v-if="product.descriptionEng">
					<p class="mobile-title">პროდუქტის აღწერა</p>
					<p>{{ product.descriptionEng }}</p>
					<p class="see-more">მეტის ნახვა...</p>
				</div>
				<div v-if="product.attributes">
					<div v-for="attr in product.attributes" :key="attr.id" class="single-description">
						<p class="single-title">{{ attr.nameEng }}</p>
						<p class="single-value">{{ attr.value.valueEng }}</p>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';

export default {
	components: {
		BModal,
	},
	props: {
		product: {
			type: Object,
			default: () => {
			},
		},
	},
	computed: {
		mainImage() {
			if (this.product.mainImage) return this.product.mainImage.url;
			return this.product.productConfigurations[0].mainImage.url
		}
	}
};
</script>
<style scoped lang="scss">
.bold-text{
	font-weight: bold;
}
.mobile-container {
	max-width: 375px;
	background: white;
	border-radius: 1.5rem;
	border: 1px solid gray;
	margin: 0 auto;

	.mobile-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		border-top-left-radius: 1.5rem;
		border-top-right-radius: 1.5rem;
		padding: 40px 58px;
		color: white;
		background: radial-gradient(78.78% 336.46% at 44.63% 48.44%, #4785E3 0%, #A470CB 53.13%, #E59698 100%);

		p {
			margin-bottom: 0;
		}
	}

	.gray-text {
		color: #B8B7BD;
	}

	.mobile-image-container {
		border-top-left-radius: 1.5rem;
		border-top-right-radius: 1.5rem;
		margin-top: -1rem;
		padding-top: 1rem;
		margin-bottom: 1rem;
		background: white;
		width: 100%;
		position: relative;

		.slider-svg {
			position: absolute;
			bottom: 1rem;
			left: calc(50% - 22.5px);
		}

		img {
			display: block;
			max-width: 280px;
			margin: 0 auto;
			border-radius: 1rem;
		}
	}

	.mobile-title {
		padding: 0 2rem;

		.product-title {
			font-size: 1rem;
			color: black;
			font-weight: bold;
		}
	}

	.mobile-configuration {
		margin-top: 2rem;
		margin-bottom: 2.5rem;
		padding: 0 2rem;

		.color-circle {
			width: 31px;
			height: 31px;
		}

		.single-configuration {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 12px 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		}
	}

	.mobile-sale {
		padding: 0 9px;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 0;
			pointer-events: none;
			background-image: linear-gradient(to bottom,
					rgba(255, 255, 255, 0),
					rgba(255, 255, 255, 1) 90%);
			width: 100%;
			height: 4em;
		}

		.sale-top {
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			padding: 1rem;
			font-size: 12px;
			background: radial-gradient(78.78% 336.46% at 44.63% 48.44%, #4785E3 0%, #A470CB 53.13%, #E59698 100%);
		}

		.sale-bottom {
			background: rgba(169, 152, 152, 0.15);;
			opacity: 0.8;
			padding: 1rem;
			position: relative;

			p {
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}

			.sale-more-container {
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				top: 0;
				left: 0;
				align-items: center;
				justify-content: center;
			}

			.sale-more-btn {
				border-radius: 80px;
				padding: 19px 56px;
				color: white;
				background: linear-gradient(84.55deg, #C8545E 2.52%, #E59698 33.17%, #F5BB57 79.07%);
			}
		}
	}

	.mobile-description {
		margin-top: 97px;
		padding: 0 9px;

		.mobile-title {
			text-align: center;
			color: black;
			margin-bottom: 1rem;

			font-weight: bold;
			font-size: 1.2rem;
		}

		p {
			text-align: justify;
		}

		.see-more {
			text-align: center;
			color: #4785E3;
		}

		.single-description {
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				margin: 0;
			}

			.single-value {
				color: black;
				padding: 8px;
			}
		}
	}
}
</style>
